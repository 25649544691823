import { StorageService } from './../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../popover/popover.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public user;

  constructor(
    private popoverController: PopoverController,
    private storageService: StorageService,
    private router: Router
  ) { }

  async settingsPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  ngOnInit() {
    this.user = this.storageService.getUser();
  }

  public redirect() {
    this.router.navigate(['/configuracoes/geral']);
  }

}
