import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isRemainder;
  public isRemainder1;
  public isRemainder2;
  constructor() {}
}
