import { UsuarioService } from './../../../services/usuario.service';
import { Register } from './../../../entities/usuario';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/** @title Select with custom trigger text */
@Component({
  selector: 'app-add-usuario',
  templateUrl: './add-usuario.component.html',
  styleUrls: ['./add-usuario.component.scss'],
})
export class AddUsuarioComponent implements OnInit {

  public hide = true;
  public hide2 = true;
  public dataModel: FormGroup;
  public dialog = false;
  public error;

  constructor(
    private modalCtrl: ModalController,
    public navCtrl: NavController,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private toastController: ToastController,
  ) { }

  get f() {
    return this.dataModel.controls;
  }

  ngOnInit() {
    this.dataModel = this.fb.group({
      name:['', [Validators.required]],
      last_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      password: ['', [Validators.required]],
      c_password: ['', [Validators.required]],
    });
  }

  public createUser() {
    const data: Register = this.dataModel.value;
    if (data.password !== data.c_password){
      this.dialog = true;
      this.error = 'Senha e confirmar senha devem ser iguais!';
    } else {
      this.usuarioService.createUser(data).subscribe(
        response => {
          this.message('Usuário cadastrado com sucesso!');
          this.dismiss();
          document.location.reload();
        },
        error => {
          console.log(error);
          this.dataModel.reset();
          this.dialog = true;
          this.error = 'Não foi possivel cadastrar o usuário, tente novamente!';
          // this.message('Não foi possivel cadastrar o usuário, tente novamente!');
        }
      );
    }
  }

  public dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    }).catch(() => console.log('dismiss chamado sem modal'));
  }

  public async message(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2500,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ],
      color: 'primary'
    });
    return await toast.present();
  }

}
