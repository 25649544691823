import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { IonicModule } from '@ionic/angular';
import { TextMaskModule } from 'angular2-text-mask';

import { ViaCepService } from '../../services/via-cep.service';
import { SharedModule } from '../../shared/shared.module';
import { AddUsuarioComponent } from './../../features/usuario/add-usuario/add-usuario.component';
import { EditUsuarioComponent } from './../../features/usuario/edit-usuario/edit-usuario.component';
import { UsuarioPageRoutingModule } from './usuario-routing.module';
import { UsuarioPage } from './usuario.page';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UsuarioPageRoutingModule,
    SharedModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    TextMaskModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    MatProgressSpinnerModule
  ],
  declarations: [UsuarioPage, AddUsuarioComponent, EditUsuarioComponent],
  providers: [
    ViaCepService
  ]
})
export class UsuarioPageModule {}
