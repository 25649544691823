import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ViaCepService {

  constructor(public http: HttpClient) {

  }
  callService(cep) {
      return this.http.get(`http://viacep.com.br/ws/` + cep + `/json/`);
    }

}
