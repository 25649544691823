import { StorageService } from './../../../services/storage.service';
import { UsuarioService } from './../../../services/usuario.service';
import { Register, EditUser } from './../../../entities/usuario';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/** @title Select with custom trigger text */
@Component({
  selector: 'app-edit-usuario',
  templateUrl: './edit-usuario.component.html',
  styleUrls: ['./edit-usuario.component.scss'],
})
export class EditUsuarioComponent implements OnInit {

  @Input() idUser;
  public hide = true;
  public hide2 = true;
  public dataModel: FormGroup;
  public dialog = false;
  public error;

  constructor(
    private modalCtrl: ModalController,
    public navCtrl: NavController,
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private toastController: ToastController,
    private storage: StorageService
  ) { }

  get f() {
    return this.dataModel.controls;
  }

  ngOnInit() {
    this.dataModel = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
    this.getUser();
  }

  public getUser() {
    this.usuarioService.findByUser(this.idUser)
      .subscribe(
        data => {
          this.dataModel.patchValue({
            name: data.name,
            email: data.email,
            password: ''
          });
        }
      );
  }

  public editUser() {
    const user = this.storage.getUser();
    if (this.idUser === user.id) {
      const data: EditUser = this.dataModel.value;
      if (data.password) {
        this.usuarioService.editUser(this.idUser, data).subscribe(
          response => {
            this.dismiss();
            document.location.reload();
            this.message('Alterações realizada com sucesso!');
          },
          error => {
            console.log(error);
            this.dialog = true;
            this.error = 'Não foi possivel alterar dados do usuário, tente novamente!';
            // this.message('Não foi possivel alterar dados do usuário, tente novamente!');
          }
        );
      } else {
        this.dialog = true;
        this.error = 'Digite sua senha/nova senha para finalizar as alterações!';
        // this.message('Digite sua senha/nova senha para finalizar as alterações!');
      }
    } else {
      this.dialog = true;
      this.error = 'Não é possivel alterar dados de outro usuário!';
      // this.message('Não é possivel alterar dados de outro usuário!');
    }
  }

  public dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    }).catch(() => console.log('dismiss chamado sem modal'));
  }

  public async message(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2500,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ],
      color: 'primary'
    });
    return await toast.present();
  }

}
