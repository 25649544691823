import { MatPaginatorIntl } from '@angular/material/paginator';
import { UsuarioPageModule } from './pages/usuarios/usuario.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { AngularMaterialModule } from './shared/angular-material/angular-materia.module';
import { MatPaginatorIntlPtBr } from './entities/paginator-ptbr-i8n';


registerLocaleData(localePt);


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule.forRoot(),
    UsuarioPageModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule],
  providers: [
    { provide:  LOCALE_ID, useClass: IonicRouteStrategy,
    useValue: 'pt-PT'},
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlPtBr}
],
  bootstrap: [AppComponent],
})
export class AppModule { }
