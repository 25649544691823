import { EditUser, Register } from './../entities/usuario';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { API_CONFIG } from '../config/api.config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private http: HttpClient,
    public storage: StorageService
  ) { }

  public findAllUsers(): Observable<any> {
    return this.http.get(`${API_CONFIG.baseUrl}/api/user`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storage.getToken()}`)
    });
  }

  public findByUser(id): Observable<any> {
    return this.http.get(`${API_CONFIG.baseUrl}/api/user/${id}`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storage.getToken()}`)
    });
  }

  public createUser(data: Register): Observable<any> {
    return this.http.post(`${API_CONFIG.baseUrl}/api/user`, data, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storage.getToken()}`)
    });
  }

  public editUser(id: number, data: any): Observable<any> {
    return this.http.put(`${API_CONFIG.baseUrl}/api/user/${id}`, data, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storage.getToken()}`)
    });
  }

  public editPassword(id: number, data: any): Observable<any> {
    return this.http.put(`${API_CONFIG.baseUrl}/api/user/password/${id}`, data, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storage.getToken()}`)
    });
  }

  public delete(id): Observable<any> {
    return this.http.delete(`${API_CONFIG.baseUrl}/api/user/${id}`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${this.storage.getToken()}`)
    });
  }
}
