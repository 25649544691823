import { EditUsuarioComponent } from './../../features/usuario/edit-usuario/edit-usuario.component';
import { UsuarioService } from './../../services/usuario.service';
import { AddUsuarioComponent } from './../../features/usuario/add-usuario/add-usuario.component';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.page.html',
  styleUrls: ['./usuario.page.scss'],
})
export class UsuarioPage implements OnInit {

  public displayedColumns: string[] = ['name', 'last_name', 'email', 'phone', 'buttons'];
  public pessoaDataSource = new MatTableDataSource();
  public dados;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalCtrl: ModalController,
    private usuarioService: UsuarioService,
    private alertController: AlertController,
    private toastController: ToastController
  ) {
    this.usuarioService.findAllUsers().subscribe(
      data => {
        this.dados = true;
      }
    );
   }

 ionViewWillEnter() {
    this.findAllUsers();
  }

  ngOnInit() {

   }

  public async findAllUsers() {
    await this.usuarioService.findAllUsers().subscribe(
      data => {
        this.dados = true;
        const users = data.data;
        this.pessoaDataSource = new MatTableDataSource(users);
        this.pessoaDataSource.paginator = this.paginator;
        this.pessoaDataSource.sort = this.sort;
      }
    );
  }

  public async editUser(id) {
    const modal = await this.modalCtrl.create({
      component: EditUsuarioComponent,
      componentProps: {
        idUser: id,
      },
      cssClass: 'large-modal'
    });
    return await modal.present();
  }

  public filterTable(value: string) {
    this.pessoaDataSource.filter = value.trim().toLocaleLowerCase();
  }

  public trackByGerentesList(index, pessoa) {
    return pessoa.id;
  }

  public async presentModal() {
    const modal = await this.modalCtrl.create({
      component: AddUsuarioComponent,
      cssClass: 'large-modal'
    });

    return await modal.present();
  }

  async openDeleteGerenteAlert(gerente) {
    const alert = await this.alertController.create({
      message: `Você irá excluir o usuário <strong>${gerente.name}</strong>. Tem certeza?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'btn-cancel'
        }, {
          text: 'Excluir',
          cssClass: 'btn-delete',
          handler: () => {
            this.usuarioService.delete(gerente.id).subscribe(
              dados => {
                this.findAllUsers();
                this.displayToast('Usuário removido com sucesso!');
              },
              (error) => {
                let message = 'Não foi possível remover este usuário.';
                message += '\n' + error.error.error.message;
                this.displayToast(message);
              }
            );
          }
        }
      ]
    });
    await alert.present();
  }

  private async displayToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        }
      ],
      color: 'primary'
    });

    return await toast.present();
  }

}
