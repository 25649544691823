import { StorageService } from './../../services/storage.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EditUsuarioComponent } from 'src/app/features/usuario/edit-usuario/edit-usuario.component';


@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  public user;

  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private tabTitle: Title,
    private authService: AuthService,
    private storageService: StorageService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.user = this.storageService.getUser();
  }

  public loggout() {
    this.tabTitle.setTitle('Novakio Dash');
    this.close();
    this.router.navigate(['/login']);
    this.authService.logout();
  }

  public async myProfile() {
    // const modal = await this.modalCtrl.create({
    //   component: EditUsuarioComponent,
    //   componentProps: {
    //     idUser: this.user.id,
    //   },
    //   cssClass: 'large-modal'
    // });
    // this.close();
    // return modal.present();
    this.router.navigate(['/configuracoes/geral']);
    this.close();
  }

  close() {
    this.popoverController.dismiss();
  }
}
