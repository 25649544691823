import { LocalUser, User } from './../entities/local_user';
import { StorageService } from './storage.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG } from '../config/api.config';
import { CredenciaisDTO } from '../entities/credenciais.dto';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    public storageService: StorageService
  ) { }

  public authenticate(creds: CredenciaisDTO): Observable<any> {
    return this.http.post(`${API_CONFIG.baseUrl}/api/login`, creds, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  public successfulLogin(token: string, user: any) {
    const users: LocalUser = {
      token: token,
      user: user
    };
    this.storageService.setLocalUser(users);
  }

  public logout() {
    this.storageService.setLocalUser(null);
    return this.http.post(`${API_CONFIG.baseUrl}/api/logout`, this.storageService.getToken(), {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

}
