import { STORAGE_KEYS } from './../config/storage_keys.config';

import { Injectable } from '@angular/core';
import { LocalUser } from '../entities/local_user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public getLocalUser() {
    const user = localStorage.getItem(STORAGE_KEYS.localUser);
    if (user == null) {
      return null;
    } else {
      return JSON.parse(user);
    }
  }

  public getToken() {
    const user = this.getLocalUser();
    return user.token;
  }

  public getUser() {
    const user = this.getLocalUser();
    return user.user;
  }

  public getIdUser() {
    const user = this.getLocalUser();
    return user.user.id;
  }

  public setLocalUser(obj: LocalUser) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.localUser);
    } else {
      localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
    }
  }
}
